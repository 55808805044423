<div class="gallery-container" *ngIf="galleryConfig">
    <div class="album" *ngFor="let album of galleryConfig">
        <h2
            [ngStyle]="{
                color: albumNameTextColor
            }"
        >
            {{ album.name }}
        </h2>
        <div class="media-container">
            <div
                *ngFor="
                    let item of isLimited
                        ? album.image.slice(0, 7)
                        : album.image;
                    let i = index
                "
                class="media-wrapper"
            >
                <div class="media-header">
                    <span>
                        {{
                            item.tooltipAltText || clearImageName(item.url)
                                | slice: 0 : 20
                        }}
                        <span *ngIf="item?.tooltipAltText?.length > 20">
                            ...
                        </span>
                    </span>
                    <div style="display: flex; flex-direction: row">
                        <button
                            (click)="downloadMedia(item.url)"
                            style="display: flex; margin-right: 5px"
                        >
                            <mat-icon>download</mat-icon>
                        </button>
                        <button
                            *ngIf="isInternal"
                            (click)="onDeleteClick(item, album.name)"
                            style="display: flex"
                        >
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
                <img
                    *ngIf="isImage(item.url)"
                    [appLazyLoad]="item.url"
                    [alt]="item.tooltipAltText"
                    loading="lazy"
                    class="media-item lazy-loading"
                    (click)="openMedia(item)"
                />
                <span (click)="openMedia(item)">
                    <video
                        *ngIf="isVideo(item.url)"
                        class="media-item"
                        (click)="openMedia(item)"
                    >
                        <source [src]="item.url" />
                        Your browser does not support the video tag.
                    </video>
                </span>

                <img
                    *ngIf="isPdf(item.url)"
                    src="../../../assets/webConf/pdf.png"
                    alt="PDF"
                    loading="lazy"
                    class="pdf-icon media-item"
                    (click)="openMedia(item)"
                />
            </div>
            <div
                *ngIf="isLimited && album.image.length > 7"
                class="media-wrapper media-item more-container"
            >
                <mat-icon (click)="navigatePage('media')" class="more-icon">
                    arrow_forward_ios
                </mat-icon>
            </div>
        </div>
    </div>

    <div *ngIf="selectedMedia" class="overlay" (click)="closeMedia()">
        <div class="modal" (click)="$event.stopPropagation()">
            <div class="media-header modal-header">
                <span>
                    {{
                        selectedMedia.tooltipAltText ||
                            clearImageName(selectedMedia.url) | slice: 0 : 100
                    }}
                </span>
                <div style="display: flex; flex-direction: row">
                    <button
                        (click)="downloadMedia(selectedMedia.url)"
                        style="display: flex; margin-right: 5px"
                    >
                        <mat-icon>download</mat-icon>
                    </button>
                    <button (click)="closeMedia()" style="display: flex">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
            <iframe
                class="expanded-media"
                style="height: 90%"
                *ngIf="selectedMedia.type === 'pdf'"
                [src]="selectedMedia.url | sanitizeHtml: 'resourceUrl'"
            ></iframe>
            <video
                *ngIf="selectedMedia.type === 'video'"
                [src]="selectedMedia.url"
                controls
                class="expanded-media"
            >
                Your browser does not support the video tag.
            </video>
            <img
                *ngIf="selectedMedia.type === 'image'"
                [appLazyLoad]="selectedMedia.url"
                loading="lazy"
                class="expanded-media lazy-loading"
                [alt]="selectedMedia.tooltipAltText"
                [matTooltip]="selectedMedia.tooltipAltText"
            />
        </div>
    </div>
</div>
